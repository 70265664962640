@import '/src/styles/mixin.scss';

// TODO
:global {
	header {
		@include tablet {
			padding: 13px 16px 12px 16px !important;
		}
	}
}

.content-page{
	padding-top: calc(72px + 32px);
	color: #FFF;

	@include tablet {
		padding-top:53px;
	}

	@include mobile {
		padding-top:53px;
	}
}