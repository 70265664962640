@import '/src/styles/mixin.scss';

.wrapper {
	height: 145px;
	width: 385px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	gap: 30px;

	@include mobile {
		scale: 0.4;
	}

	.image {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		img {

			&.kion {
				width: 177px;
				height: 42px;

				&Colored {
					position: absolute;
					top: -50px;
					min-width: 550px;
    				height: 145px;
					object-fit: cover;
				}
			}

			&.originals {
				width: 386px;
				height: 42px;
			}
		}

	}
}