@import '/src/styles/mixin.scss';

.label {
	color: #FFF;
	/* Paragraph/P3 Regular Comp */
	font-family: MTS Compact;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 141.176% */

	&.grey {
		color: #677F99;
	}

	&.small {
		font-weight: 400;
	}
}

.selectedFilters {
	display: none;

	@include tablet {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
	}

	> * {
		display: flex;
		padding: 6px 16px;
		justify-content: center;
		align-items: center;
		gap: 4px;
		align-self: stretch;
		border-radius: 100px;
		background: var(--web-secondary-blue, #122840);
	}
}

.filtersItems {
	display: flex;
	flex-direction: column;
	gap: 32px;

	&MobileShown {		
		.filtersWrapper {
			display: flex !important;
			position: fixed;
			inset: 0;
			width: 100%;
			height: 100%;
			overflow: auto;
			background: #001424;
			z-index: 100;

			padding: 0px 98px;
			opacity: 0.985;
			gap: 24px;

			padding-top: 60px;

			@include mobile {
				padding: 0px 16px;
				padding-top: 40px;
				padding-bottom: 190px;
			}

			.cross {
				display: unset !important;
				position: absolute;
				top: 20px;
				right: 20px;
			}

			.mainLabel {
				color: var(--web-primary-white, #FFF);
				font-feature-settings: 'clig' off, 'liga' off;
				font-family: MTS Compact;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 32px; /* 133.333% */
			}
		}

		.mobileButtons {
			display: flex !important;
			position: fixed;
			z-index: 100;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			max-width: 572px;
			width: 100%;
			padding: 10px;
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;
			border-radius: 16px 16px 0px 0px;
			background: #FFF;
		}
	}

	.mobileButtons {
		display: none;
	}

	.staticItem {
		@include tablet {
			display: none !important;
		}
	}

	.filtersWrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@include tablet {
			display: none;
		}

		.cross {
			display: none;
		}

		.filters {
			display: flex;
			flex-direction: column;
			gap: 16px;

			&Item {
				display: flex;
				flex-direction: column;
				gap: 12px;

				> * {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}

				.radio, .checkbox {
					> * {
						display: flex;
						align-items: center;
						gap: 8px;
					}
				}
			}

			.textBtn {
				color: var(--web-primary-bright-blue, #45B7FC);
				font-feature-settings: 'clig' off, 'liga' off;

				/* Paragraph/P3 Regular Comp */
				font-family: MTS Compact;
				font-size: 17px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 141.176% */
			}
		}
	}

	.item {
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
	}
}