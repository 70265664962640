@import '/src/styles/mixin.scss';

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 62px;
	max-width: 95vw;
	padding: 0 50px;

	@include mobile {
		gap: 25px;
		flex-direction: column;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.images {
		z-index: 1;
		position: relative;

		.frame {
			width: 100%;
			height: 100%;
			aspect-ratio: 778/460;
			background-image: url('../../../../../public/main/8/frame.svg');
			background-size: cover;
			display: flex;
			align-items: center;
			justify-content: center;

			video {
				width: 97%;
				height: 92%;
				position: relative;
				z-index: 2;
				object-fit: cover;
				top: -3px;
			}
		}

		.smallDescription {
			font-family: 'MTS Compact';
			font-style: normal;
			font-weight: 400;
			font-size: 10px;
			line-height: 14px;
			color: #75537B;

			@include mobile {
				font-size: 3px;
			}
		}
	}

	.infoWrapper {
		display: flex;
		flex-direction: column;
		max-width: 600px;
		width: 100%;

		.infoItem {
			display: flex;
			flex-direction: column;
			color: #FFFFFF;
			overflow: hidden;

			.title {
				font-family: 'MTS Compact';
				font-style: normal;
				font-weight: 700;
				font-size: 34px;
				line-height: 48px;

				@include mobile {
					font-size: 20px;
					line-height: 22px;
				}
			}

			.description {
				font-family: 'MTS Compact';
				font-style: normal;
				font-weight: 400;
				font-size: 32px;
				line-height: 45px;

				@include mobile {
					font-size: 16px;
					line-height: 28px;
				}
			}
		}
	}
}