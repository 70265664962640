
.wrapper {
    .instruction-link{
        color:#45B7FC;
        padding-left:32px;
        position:relative;
        display:inline-block;
        margin-bottom:16px;

        span{
            position:absolute;
            left:0;
            top:50%;
            margin-top:-12px;
            width:24px;
            height:24px;

            svg{
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
            }
        }
    }

    .grey {
        color: var(--web-primary-gray, #677F99);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Paragraph/P3 Regular Text */
        font-family: MTS Text;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 141.176% */
        margin-top: 32px;
        margin-bottom: 24px;
    }

    .service {
        color: var(--web-primary-bright-blue, #45B7FC);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Paragraph/P3 Regular Text */
        font-family: MTS Text;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .instrWrapper {
        display: flex;
        gap: 30px;
    }
}