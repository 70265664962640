@import '/src/styles/mixin.scss';

.orders {
    border-radius: 100px;
    background: var(--web-secondary-blue, #122840);
    display: flex;
    padding: 6px 16px 6px 14px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;

    span {
        color: var(--web-primary-white, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: MTS Compact;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 141.176% */
    }

    @include tablet {

    }
}