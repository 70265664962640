@import '/src/styles/mixin.scss';

/*--- Header ---*/
.header{
	padding:14px 0;
	position:fixed;
	z-index: 99;
	top:0;
	left:0;
	right:0;
	background-color: var(--color-primary-bg);

	@media (max-width: 1045px){
		position: unset;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		padding-top: 50px;
	}

	.mobileLogo {
		display: none;

		@media (max-width: 1045px){
			display: unset;
		}
	}

	.wrapper{
		max-width: 1104px;
		margin:auto;
		display:flex;
		flex-wrap:wrap;
		align-items: center;
		justify-content:space-between;

		@media (max-width: 1045px){
			display: none;
		}

		.logo{
			flex:180px 0 0;
			padding:0 8px;

			svg{
				height:38px;
				width:auto
			}
		}

		.nav{
			display:-webkit-box;
			display:-ms-flexbox;
			display:flex;
			-ms-flex-wrap:wrap;
			flex-wrap:wrap;
			flex: 1 1 0;

			a{
				font-size:17px;
				line-height:24px;
				margin:0 12px;
				color:white;
				position: relative;

				&:hover {
					color: #ba9d5a;

					&::after {
						opacity: 1;
					}
				}

				&::after {
					position: absolute;
					content: "";
					bottom: -100%;
					background-color: #ba9d5a;
					left: 0;
					width: 100%;
					height: 2px;
					border-radius: 1px;
					transition: opacity .3s linear;
					opacity: 0;
				}
			}
		}

		.enter{
			padding:0 8px;
		}
		.enter a {
			color: white;
		}
		.enterBtn{
			font-family: 'MTS Compact';
			font-weight: 500;
			position:relative;
			padding:8px 0;
			padding-left:28px;
			font-size:17px;
			line-height:24px;
			color:white;
			cursor: pointer;
		}
		.enterBtn:before{
			position:absolute;
			content:'';
			width:24px;
			height:24px;
			top:50%;
			margin-top:-12px;
			left:0;
			background-position:center;
			background-repeat:no-repeat;
			background-image: url("../../../public/header/enter.svg");
			background-position:center;
			background-repeat:no-repeat;
		}
		.profileAvatar {
			margin-left: 16px;
			width: 32px;
			height: 32px;
			border-radius: 50%;
		}
	}
}

@include tablet {
	.nav,
	.enter{
		// display:none
	}
	.logo{
		padding:0
	}
	.logo svg {
		width: 85px;
		height: auto
	}
	.header{
		position:absolute;
		padding:13px 0 20px;
		// background:rgba(0, 20, 36, 1)
	}

}

@include mobile {
	.header{
		padding:13px 0 9px;
	}
	.header svg{
		vertical-align:bottom
	}
}

/*--- End Header ---*/