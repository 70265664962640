@import "./source.css";
@import "../components/input-search/index.css";
@import "./fonts/fonts.css";
@import "swiper/css";

:root {
  --color-primary-bg: #000;
}

* {
	box-sizing: border-box;
  font-family: 'MTS Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* .swiper-slide-thumb-active svg{
	fill:white !important
} */

html {
  height: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 767px) {
  html {
    scroll-behavior: auto !important;
  }
}

body,
html {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary-bg);
}

a {
  color: inherit;
  text-decoration: none;
}

.pseudo-hidden {
	position: absolute;
	opacity: 0;
	z-index: -99;
}

.blank {
  height: 100vh;
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.invisible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute !important;
}

.sticky-view-sticky {
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.p-sticky {
  position: sticky !important;
}