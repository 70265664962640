@import '/src/styles/mixin.scss';

.wrapper {
    
    p {
        /* Paragraph/P1 Regular Comp */
        font-size: 16px;
        font-family: 'MTS Compact';
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @include tablet {
            font-size: 14px;
            line-height: 20px;
        }

        @include mobile {
            font-size: 14px;
            line-height: 20px;
        }
    }

    small {
        display: inline-block;
        
        color: var(--web-primary-gray, #677F99);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Paragraph/P4 Regular Comp */
        font-family: MTS Compact;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */

        margin-top: 48px;
    }
}