.footer{
	padding: 40px;
	color:#FFF;
	font-size: 12px;
	font-family: 'MTS Sans';
	font-weight: 400;
	line-height: 16px;
	
	display: flex;
	justify-content: center;
}