@import '/src/styles/mixin.scss';

.tvBlock {
	position: relative;
	z-index: 3;
	transition: all 0.2s ease;
	max-width: 70vw;
	aspect-ratio: 1088/772;
	margin-bottom: -5%;
	max-height: 90vh;
	height: 100%;

	@include mobile {
		margin-bottom: -30vh;
		max-width: 80vw;
		width: 100%;
		z-index: 4;
	}

	.tv {
		position: relative;
		transition: all 0.2s ease;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
		}

		video {
			position: absolute;
			width: 97%;
			height: 95%;
			left: 1.5%;
			top: -1%;

			@include mobile {
				top: -4%
			}
		}
	}

	.glow {
		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			inset: 0;
			transition: all 0.2s ease;
		}

		&Red {
			&::before {
				background: linear-gradient(180deg, rgba(250, 0, 255, 0.65) 0%, #4D0D6F 100%);
				filter: blur(101.966px);
			}
		}

		&Light {
			&::before {
				background: linear-gradient(180deg, rgba(0, 209, 255, 0.65) 0%, #4D0D6F 100%);
				opacity: 0.5;
				filter: blur(66.3029px);
			}
		}
	}
}