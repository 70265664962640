/* WideMedium */

@font-face {
	font-family: 'MTS WideMedium';
	src: url('./Wide/MTSWide-Black.otf');
	font-weight: 1000;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'MTS WideMedium';
	src: url('./Wide/MTSWide-Bold.otf');
	font-weight: bold;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'MTS WideMedium';
	src: url('./Wide/MTSWide-Medium.otf');
	font-weight: 500;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'MTS WideMedium';
	src: url('./Wide/MTSWide-Regular.otf');
	font-weight: 400;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'MTS WideMedium';
	src: url('./Wide/MTSWide-Light.otf');
	font-weight: 300;
	font-style: normal;
  font-display: swap;
}

/* MTS Sans */

@font-face {
  font-family: 'MTS Sans';
  src: url('./Sans/MTSSans-UltraWide.otf');
  font-style: normal;
  font-weight: 1000;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Sans';
  src: url('./Sans/MTSSans-Black.otf');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Sans';
  src: url('./Sans/MTSSans-Bold.otf');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Sans';
  src: url('./Sans/MTSSans-Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Sans';
  src: url('./Sans/MTSSans-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* UltraExtended */

@font-face {
	font-family: 'MTS UltraExtended';
	src: url("./UltraExtended/MTSUltraExtended-Bold.otf");
	font-style: normal;
	font-weight: 700;
	font-display: swap
}

@font-face {
	font-family: 'MTS UltraExtended';
	src: url("./UltraExtended/MTSUltraExtended-Regular.otf");
	font-style: normal;
	font-weight: 400;
	font-display: swap
}

/* Extended */

@font-face {
	font-family: 'MTS Extended';
	src: url("./Extended/MTSExtended-Medium.otf");
	font-style: normal;
	font-weight: 500;
	font-display: swap
}

/* Compact */

@font-face {
	font-family: 'MTS Compact';
	src: url("./Compact/MTSCompact-Medium.otf");
	font-weight: 500;
	font-style: normal;
	font-display: swap
}

@font-face {
	font-family: 'MTS Compact';
	src: url("./Compact/MTSCompact-Regular.otf");
	font-weight: 400;
	font-style: normal;
	font-display: swap
}