.buy-btn{
    font-family: 'MTS Compact';
    font-weight: 500;
    font-size:17px;
    color:white;
    text-align:center;
    display:inline-block;
    width:100%;
    height:56px;
    line-height:56px;
    background:#001424;
    border-radius: 16px;
  }
  .buy-btn:hover {
    color: white;
  }