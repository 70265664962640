@import '/src/styles/mixin.scss';

.swiper-container {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	z-index: 1;
}

.tabss {
	.labels {
		  width: fit-content;
		  &:hover {
				:global .swiper-slide-thumb-active {
					&::after {
						width: 0 !important;
					}	
				}
		  }
	}

	.tab-label {
	  width: auto !important;
	}

	:global {
		.swiper-slide-thumb-active {
			color: white !important;
	
			&::after {
				width: 100% !important;
			}
		}
	}
}

/*--- Tabs ---*/
.tabss{
	margin-top:56px
}
.slider-big-tabs .swiper-slide{
	cursor:pointer;
	padding-right:20px;
	font-size:17px;
	line-height:24px;
	color:#93A8BF;

	transition-duration: 0.5s;
}
.slider-big-tabs .swiper-slide:hover{
	color:white
}
.slider-big-tabs .swiper-slide:first-of-type{
	width:83px
}
.slider-big-tabs .swiper-slide:nth-of-type(2){
	width:137px
}
.slider-big-tabs .swiper-slide:nth-of-type(3){
	width:212px
}
.slider-big-tabs .swiper-slide:nth-of-type(4){
	width:96px
}
.slider-tabs{
	margin-top:48px
}
.slider-big-tabs{
	padding-bottom:10px;
}
.slider-big-tabs .swiper-slide-thumb-active{
	color:white;
}
.slider-big-tabs .swiper-slide:after{
	transition-duration: 0.5s;
	position:absolute;
	content:'';
	background:white;
	height:2px;
	bottom:-8px;
	width:0;
	left:0;
	max-width: calc(100% - 20px);
}
.slider-big-tabs .swiper-slide:hover:after,
.slider-big-tabs .swiper-slide-thumb-active:after{
	width:100%;
}

.slider-tabs .swiper-slide{
	display:block;
}
.slider-tabs h2{
	font-size:24px;
	line-height:32px;
	font-family: 'MTS sans';
	font-weight: 700;
	margin-bottom:16px;
}
.slider-tabs p + h2{
	margin-top:32px
}

.swiper-autoheight .swiper-wrapper {
	align-items: flex-start;
	transition-property: height,-webkit-transform;
	transition-property: transform,height;
	transition-property: transform,height,-webkit-transform;
}
.swiper-autoheight,
.swiper-autoheight .swiper-slide {
	height: auto;
}



@include tablet {
	.tabss {
		margin-left: 24px;
		margin-right: 24px;
	}
	.slider-big-tabs .swiper-slide{
		font-size:14px;
		line-height:20px;
	}
	.slider-big-tabs .swiper-slide:first-of-type{
		width:62px
	}
	.slider-big-tabs .swiper-slide:nth-of-type(2){
		width:102px
	}
	.slider-big-tabs .swiper-slide:nth-of-type(3){
		width:190px
	}
	.slider-big-tabs .swiper-slide:nth-of-type(4){
		width:85px
	}
	.slider-tabs h2 {
		font-size: 20px;
		line-height: 28px;
	}
	.slider-tabs{
		font-size:17px;
		line-height:24px;
	}
}

@include mobile {
	.tabss {
		margin-top: 40px;
	}
	.slider-tabs {
		margin-top: 42px;
	}
	.slider-tabs h2 {
		font-size: 17px;
		line-height: 24px;
		margin-bottom:8px;
	}
	.slider-tabs {
		font-size: 14px;
		line-height: 20px;
	}
	.slider-big-tabs .swiper-slide:nth-of-type(4) {
		margin-right:43px;
	}
}
/*--- End Tabs ---*/