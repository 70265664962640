@import '/src/styles/mixin.scss';

.headerBlock {
	display: flex;
    justify-content: space-between;
    align-items: center;
	margin-bottom: 32px;
}

.wrapper{
	width: 1100px;
	margin: 0 auto;
	padding:0 8px;

	@include tablet {
		padding:0;
		width: 100%;
	}
}