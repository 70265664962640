@import '/src/styles/mixin.scss';

.white-block{
	position:relative;
	background:white;
	color:#001424;
	text-align:left;
	width:100%;
    height: 420px;
	padding: 74px 95px 0;
	border-radius: 40px;
	
    display: flex;  
	justify-content: space-between;
    // gap: 50px;

    @include tablet {
        height: auto;
        flex-direction: column-reverse;
        align-items: center;
	    padding: 30px 15px;
        gap: 10px;
    }

    .infoblock {
        max-width: 440px;
        width: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 9px;

        @include tablet {
            align-items: center;
        }

        .wbh2{
            font-family: 'MTS WideMedium';
            font-size: 32px;
            line-height: 36px;
            margin-bottom: 0px;
        }

        .description {        
            font-family: 'MTS Compact';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;

            p { }

            ol {
                padding-left: 20px;
                list-style: auto;
                margin-bottom: 0;

                li {
                    a {
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 1px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background: #000;
                        }
                    }
                }

                span{
                    position:absolute;
                    left:0;
                    top:0;
                }
            }
        }

        .tooltip {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
            gap: 4px;
            border-radius: 10px;
            background: #CAECFF;

            color: #284059;
            font-size: 10px;
            font-family: 'MTS Compact';
            width: fit-content;
        }

        .blocks {
            display: flex;
            flex-wrap: wrap;
            gap: 9px;

            @include tablet {
                justify-content: center;
            }

            > * {
                height: 45px;
                width: 126px;
                display: flex;
            }
        }
    }

    .main-img {
        max-width: 500px;
        width: 100%;
        margin-bottom: 75px;
        position: relative;

        @include tablet {
            scale: unset !important;
            margin-bottom: unset;
        }

        @include mobile {
            max-width: none;
        }

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;

            @include tablet {
                scale: unset !important;
                margin-top: unset !important;
            }
        }

        video {
            position: absolute;
            width: 84%;
            height: 80%;
            left: 8.3%;
            object-fit: cover;
            top: 3%;

            @include tablet {
                height: 66%;
                top: 11%;
            }
		}
    }
}