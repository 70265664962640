@import '/src/styles/mixin.scss';

.wrapper {
    p {
        margin-bottom: 0;
    }

    .flexx{
        display: flex;
        flex-wrap:wrap;
        margin:16px -8px 0;
    }
    .fl-block{
        width:33.3333%;
        padding:8px;

        @include tablet {
		    width: 50%
        }

        @include mobile {
            width: 100%;
        }
    }
    .fl-block-content{
        padding:16px;
        position:relative;
        background:#122840;
        border-radius: 8px;
        color:#849AB2;
        font-size:12px;
        line-height:16px;
    
        display: flex;
        flex-wrap:wrap;
    }
    .wefw{
        flex: 1 1 0;
        padding-left:8px;
    }
    .tgrdf{
        position:relative;
        flex: 107px 0 0;
        height:62px;
        width:107px;
        border-radius: 6px;
        background:#284059;
    }
    .tgrdf svg{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    }
    .zagwefw{
        color:white;
        font-family: 'MTS Compact';
        font-weight: bold;
        font-size:17px;
        line-height:24px;
    }
}