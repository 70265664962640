.question {
  border-radius: 12px;
  background: var(--web-secondary-blue, #122840);
  padding: 24px 40px 0;
  margin-bottom: 16px;
  
  &:last-of-type {
    margin-bottom: 0;
  }
}

.question-p {
  cursor: pointer;
  position: relative;
  padding-right: 30px;
  transition-duration: 0.5s;

  color: var(--web-primary-white, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: MTS Compact;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.open .question-p {
  padding-bottom: 16px;
}

.question-p span {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -12px;
}

.question-p span svg {
  position: absolute;
  fill: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-duration: 0.5s;
}

.answer-p {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  padding-bottom: 24px;
  transition-duration: 0.5s;
  font-family: 'MTSCompactRegular';

  color: var(--web-tertiary-gray, #93A8BF);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Paragraph/P4 Regular Comp */
  font-family: MTS Compact;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  flex-direction: column;
  gap: 16px;

  .infoItem {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    color: var(--web-primary-white, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;

    /* WEB/Paragraph/P2 XL Medium */
    font-family: MTS Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.open .answer-p {
  opacity: 1;
  max-height: 400px;
  padding-bottom: 24px;
}

.open .question-p span svg {
  transform: translate(-50%, -50%) rotate(180deg);
}

@media (max-width: 1199.98px) {
  .question {
    border-radius: 12px;
    margin-bottom: 12px;
    padding: 16px 24px 0;
  }

  .answer-p {
    padding-bottom: 16px;
  }

  .open .question-p {
    padding-bottom: 12px;
  }
}

@media (max-width: 767.98px) {
  .question {
    border-radius: 12px;
    margin-bottom: 12px;
    padding: 18px 16px 0;
  }

  .question-p {
    font-size: 14px;
    line-height: 20px;
    padding-right: 30px;
  }

  .answer-p {
    padding-bottom: 18px;
  }
}