@import '/src/styles/mixin.scss';

.headerBlock {
	display: flex;
    justify-content: space-between;
    align-items: center;
	padding-bottom: 32px;

	@include tablet {
        padding: 0 24px 24px;
	}

	@include mobile {
        padding: 0 12px 16px;
	}
}

.wrapper{
	width: 1100px;
	margin: 0 auto;
	padding:0 8px;

	@include tablet {
		padding:0;
		width: 100%;
	}
}

.wrapper-info {
	@include mobile {
		// width:288px
	}
}

img{
	max-width:100%;
	height:auto;
}