/*--- Breadcrumb ---*/
.breadcrumb{
    display:block;
    font-size:14px;
    line-height:20px;
    color:#677F99;
    
    .arrow{
        margin: 0 12px;
    }

    span.active{
        color:white;
    }
    span, a {
        display:inline-block;
        vertical-align:middle;
    }

    a{
        color:#677F99;
    }
}
@media (max-width: 1199.98px){
    .breadcrumb {
        visibility: hidden;
        width: 0;
        height: 0;
    }
}
/*--- End Breadcrumb ---*/