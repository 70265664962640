@import '/src/styles/mixin.scss';

.info {
	position: relative;
	z-index: -1;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -25%;
	gap: 25px;
	opacity: 0;

	@include mobile {
		gap: 20px;
		margin-top: -290px;
	}

	img {
		width: 65% !important;
	}

	.characters {
		display: flex;
		align-items: flex-end;
		gap: 12px;

		@include tablet {
			gap: 15px;
			flex-direction: column;
			align-items: center;
		}

		.char {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-shrink: 0;

			border: 1px solid white;
			border-radius: 8px;
			padding: 10px 12px;

			font-family: 'MTS Extended';
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			// line-height: 47px;
			leading-trim: both;
			text-edge: cap;
			text-align: center;
			color: #FFFFFF;

			@include mobile {
				scale: 0.9
			}

			&:hover {
				background-color: #FFFFFF;
				color: #000;
			}

			@include mobile {
				&:not(:first-child) {
					margin-top: -10px;
				}
			}

			&Active {
				background-color: #FFFFFF;
				color: #000;
			}

			.inch {
				font-family: 'MTS Extended';
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 110%;

				@include mobile {
					font-size: 14px;
				}
			}

			.coast {
				font-family: 'MTS Compact';
				font-style: normal;
				font-weight: 400;
				font-size: 12px;

				@include mobile {
					font-size: 9px;
				}
			}
		}
	}

	.button {
		background: linear-gradient(144.11deg, #3C73B9 6.47%, #322882 127.1%);
		border-radius: 4.5px;

		font-family: 'MTS UltraExtended';
		font-style: normal;
		font-weight: 400;
		font-size: 14.625px;
		line-height: 21px;

		color: #EEEEEE;
		box-shadow: none;
		border: none;
		padding: 13px 22px;
	}
}