.wrapper {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
}

.overlay {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 20, 36, 0.95);
  
    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 720px;
      height: 416px;
      padding: 20px;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      background-image: linear-gradient(180.52deg, #0E2339 0.45%, #132F49 38.74%);
      box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.3), 0px 12px 20px rgba(0, 0, 0, 0.14);
  
      &__content {
        height: 100%;
        width: 100%;
      }
  
      &__action {
        position: absolute;
        top: 20px;
        cursor: pointer;
  
        &.back {
          left: 20px;
        }
  
        &.close {
          right: 20px;
        }
      }
  
      @media (max-width: 720px) {
        width: 663px;
      }
  
      @media (max-width: 663px) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
      }
    }
  }