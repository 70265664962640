.wrapper.full-width {
	position: absolute;
	background-color: #001424;
	justify-content: center !important;
}

.wrapper.full-width	.search {
	width: 92% !important;
}

body .row>.svg-icon svg,
body .svg-icon svg {
	height: 100%;
	width: 100%;
}

img,
svg {
	vertical-align: middle;
}

.svg-icon {
	min-width: 24px;
	height: 24px;
}

.search {
	display: flex;
	height: 44px;
	border-radius: 8px;
	box-sizing: border-box;
	position: relative;
	font-size: 17px;
	line-height: 24px;
	cursor: text;
	align-items: center;
	justify-content: space-between;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	font-family: 'MTS Sans';

	input {
		background: transparent;
		height: 100% !important;
		border: none;
		outline: none;
		font-size: inherit;
		color: inherit;
		width: 100%;

		&::placeholder {
			color: #fff;
		}
	}

	.svg-icon {
		margin-right: 4px;
	}
}

.theme-header.icon-right .svg-icon.align-right,
.theme-default.icon-right .svg-icon.align-right {
	display: inline-flex;
	margin-right: 16px;
	margin-left: 4px;
	cursor: pointer;
}

.theme-header {
	background-color: rgba(255, 255, 255, 0.2);
}

.theme-header:hover {
	background-color: #284059;
}

.theme-header:focus {
	background-color: #FFF;
}

.theme-header:focus-within svg {
	fill: #000;
}

.theme-header:active:not(:disabled):not(.invalid):not(.disabled),
.theme-header:focus-within:not(:disabled):not(.invalid):not(.disabled),
.theme-default:active:not(:disabled):not(.invalid):not(.disabled),
.theme-default:focus-within:not(:disabled):not(.invalid):not(.disabled) {
	background: #fff;
	color: #122840;
	border-color: #e2e5eb;
}

.theme-header,
.theme-default {
	border: 1px solid rgba(255, 255, 255, 0.1);
	padding: 10px 0 10px 16px;
}

.theme-header.icon-left .svg-icon.align-left,
.theme-default.icon-left .svg-icon.align-left {
	display: inline-flex;
	margin-right: 4px;
}

.theme-header.state-primary .svg-icon {
	fill: #FFF;
}

.theme-header input,
.theme-default input {
	display: inline-flex !important;
	text-transform: inherit;
	padding: 0;
	height: 24px;
	line-height: 24px;
	flex-grow: 1;
}