@import '/src/styles/mixin.scss';

.eight {
	height: 300vh;
	margin-top: -20vh;

	@include mobile {
		height: 200vh;
	}

	.bg {
		position: fixed;
		inset: 0;
		min-height: 100vh;
		min-width: 100vw;
		z-index: 0;
		transition: opacity 0.2s ease;
		pointer-events: none;
	}
}