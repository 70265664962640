@import '/src/styles/mixin.scss';

.eleven {
    height: 250vh;
    margin-top: -90vh;

	@include mobile {
		margin-top: -140vh;
	}
}

.tabs {
    margin-left: auto;
    margin-right: auto;

    .tab-label {
          position:relative;
          font-size:17px;
          line-height:24px;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          user-select: none;
          transition-duration: 0.5s;
          padding:0 8px;
          cursor: pointer;
          color:#93A8BF;
          margin-bottom:66px;

          &::after{
                transition-duration: 0.5s;
                position:absolute;
                content:'';
                bottom:-8px;
                width:0;
                left:8px;
                height:2px;
                background:white;
                max-width: calc(100% - 16px);
          }
    }

    .labels {
          display: contents;
          &:hover {
                .tab-label.tab-active::after {
                      width: 0;
                }
          }
    }

    .tab-label {
          &:hover,
          &.tab-active {
                color: white;
          }

          &.tab-active::after {
                width: 100%;
          }

          &.tab-active:hover::after {
                width: 100% !important;
          }

          &:hover::after {
                width: 100% !important;
          }

          &::after {
                width: 0;
          }
    }
}

.slider-big-1, .slider-big-2,  .slider-big-3 {
	margin-top:68px !important;
	border-radius: 40px;
	background-color: white;
}

.fourth-screen {
	padding-top:130px;
	text-align:center;

    .wrapper{
        padding: 0 8px;
        width: 1100px;
        margin: 0 auto;
    }

    .h2{
		color: #FFF;
		font-family: "MTS UltraExtended";
        font-size:32px;
        line-height:36px;
        font-weight: 700;
        text-transform:uppercase;
        margin-bottom: 48px;
    }
}

.swiper-horizontal {
    touch-action: pan-y;
}

.swiper-container {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	z-index: 1;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	box-sizing: content-box;
}

.top-thums {
	justify-content: center;
	display: flex;

	@include mobile {
		justify-content: unset;
	}

	> * {
		align-items: center;
		width: auto;
	}
}

.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: auto;
	position: relative;
	transition-property: transform;
	
	display: flex;
}

.swiper-slide svg{
	margin:auto;
	cursor:pointer;
	fill:#93A8BF
}

:global {
	.swiper-slide-thumb-active svg {
		fill:white !important;
	}
}

.slider-1{
	height:42px;
}

.slider-2{
	height:42px;
}

.slider-3{
	height:42px;
}

.slider-1 .swiper-wrapper{
	align-items:center;
}

.slider-1 .swiper-slide,
.slider-2 .swiper-slide,
.slider-3 .swiper-slide{
	margin:0 20px;
}

.logo-slide {
	width: auto;

	@include mobile {
		scale: 0.8;
	}
}

@media (max-width: 1199.98px){
	.slider-big-1, .slider-big-2, .slider-big-3 {
		margin-top:52px !important;
		border-radius: 20px;
	}
	.fourth-screen {
		overflow:hidden;
		padding-top: 56px;
		text-align: center;
	}

	.fourth-screen .wrapper {
		width:auto;
		padding:0;
		margin-left:calc(50% - 360px);
		margin-right: calc(50% - 360px);
	}

	.fourth-screen .h2 {
		margin-bottom: 16px;
	}	
	.tabs .tab-label{
		margin:0 16px 48px 0
	}
	.slider-1 {
		overflow:visible;
		margin:0
	}
	.slider-1:before{
		position:absolute;
		content:'';
		z-index:2;
		top:0;
		bottom:0;
		left:-300px;
		width:300px;
	}
	.slider-1 .swiper-slide,
	.slider-2 .swiper-slide,
	.slider-3 .swiper-slide {
		margin: 0 40px 0 0;
	}
	.slider-1 .swiper-slide:last-of-type{
		margin-right:114px
	}
}

@media (max-width: 767.98px) {
	.slider-big-1, .slider-big-2, .slider-big-3 {
		margin-top:32px !important;
		border-radius: 12px;
	}
	.fourth-screen .wrapper {
		width:288px;
		padding: 0;
		margin-left: calc(50% - 144px);
	}

	.fourth-screen {
		width: 100%;
		overflow:hidden;
		padding-top: 42px;
		text-align: center;
	}

	.tabs .tab-label{
		font-size:14px;
		line-height:20px;
		margin:0 8px 48px 8px;
	}

	.slider-1 .swiper-slide,
	.slider-2 .swiper-slide,
	.slider-3 .swiper-slide{
		margin:0;
		margin-right:24px;

		@include mobile {
			margin-right: 5px;
		}
	}
	.slider-1 .swiper-slide:last-of-type {
		margin-right: 110px;
	}
}

@media (max-width: 450px) {
	.fourth-screen .wrapper {
		width:95%;
		padding: 0;
		margin-left: 3%;
	}
}
/*--- End Fourth Screen ---*/