@import '/src/styles/mixin.scss';

.wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;

	@include mobile {
		top: 5%;
		left: -5vw;
	}
}

.poster {
	transition: all 0.2s ease;
	position: absolute;
	z-index: 0;
	max-width: 30vw;
	width: 25%;

	@include mobile {
		transition: all 0.5s ease;
	}
}