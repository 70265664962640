@import '/src/styles/mixin.scss';

.wrapper{
	width: 1100px;
	margin: 0 auto;
	padding:0 8px;

	@include tablet {
		padding:0;
		width: 100%;
	}

	@include mobile {
		
	}

	.label {
		color: #FFF;
		/* Paragraph/P3 Regular Comp */
		font-family: MTS Compact;
		font-size: 17px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px; /* 141.176% */

		@include mobile {
			font-size: 14px;
		}

		&.grey {
			color: #677F99;
		}

		&.small {
			font-weight: 400;
		}
	}

	.catalogWrapper {
		margin-top: 32px;
		display: flex;
		width: 100%;
		justify-content: space-between;
		gap: 72px;

		@include tablet {
			flex-direction: column;
			align-items: center;
			gap: 24px;
			padding: 0 16px;
		}

		.props {
			display: flex;
			flex-direction: column;
			gap: 32px;
			max-width: 244px;
			width: 100%;
			
			@include tablet {
				max-width: 100%;
				gap: 24px;
			}

			.head {
				display: flex;
				align-items: center;
				width: 100%;

				.search {
					width: 100%;
				}

				&, > * {
					@include tablet {
						gap: 24px;
					}

					@include mobile {
						gap: 12px;
					}
				}

				.mobileFilters {
					display: none;

					@include tablet {
						display: flex;
					}

					@include mobile {
						display: flex;
					}

					> * {
						padding: 10px;
						border-radius: 8px;
						background: var(--web-secondary-blue, #122840);
					}
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 24px;
			width: 100%;

			.card {
				display: flex;
				gap: 32px;
				padding: 32px;
				color: black;
				background-color: white;
				border-radius: 30px;
				max-width: 772px;
				width: 100%;

				@include mobile {
					flex-direction: column;
					align-items: center;
					gap: 12px;
				}

				.image {
					display: flex;
					flex-direction: column;
					width: 272px;
					height: 218px;
					padding: 12px 24px;
					gap: 12px;
					background-color: #f2f3f7;
					border-radius: 20px;
					flex-shrink: 0;

					@include mobile {
						width: 100%;
						height: 189px;
					}

					img {
						width: 100%;
						height: 100%;
						max-height: 150px;
						object-fit: contain;

						@include mobile {
							max-height: 120px;
						}
					}

					.gift {
						display: flex;
						padding: 6px 12px;
						justify-content: center;
						align-items: center;
						gap: 4px;
						border-radius: 100px;
						background: rgba(0, 112, 229, 0.10);
						
						color: #0070E5;
						font-feature-settings: 'clig' off, 'liga' off;
					  
						/* Paragraph/P4 Regular Comp */
						font-family: MTS Compact;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
					  
						@include mobile {
						  font-size: 12px;
						  line-height: 16px;
						}
					  }
				}

				.card-info {
					display: flex;
					flex-direction: column;
					gap: 12px;
					width: 100%;

					.title {
						/* Header/H3 Wide */
						font-family: MTS Wide;
						font-size: 24px;
						font-style: normal;
						font-weight: 500;
						line-height: 28px; /* 116.667% */

						@include mobile {
							font-size: 20px;
						}
					}

					.specs {
						display: flex;
						flex-direction: column;
						gap: 4px;

						> * {
							display: flex;
							gap: 8px;
						}
					}

					.bottom {
						display: flex;
						align-items: center;

						@include mobile {
							flex-direction: column;
							gap: 12px;
						}

						.price {
							width: 100%;

							color: var(--web-primary-blue, #001424);
							font-feature-settings: 'clig' off, 'liga' off;
							/* WEB/Header/H1 XL */
							font-family: MTS Sans;
							font-size: 32px;
							font-style: normal;
							font-weight: 700;
							line-height: 36px; /* 112.5% */

							@include mobile {
								font-size: 24px;
							}
						}
					}
				}
			}
		}
	}

	.notFound{
		display: flex;
		flex-direction: column;
		gap: 16px;

		.title {
			color: var(--web-primary-white, #FFF);
			text-align: center;
			font-feature-settings: 'clig' off, 'liga' off;
			/* WEB/Header/H2 MD */
			font-family: MTS Sans;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 28px; /* 140% */
		}

		.description {
			color: var(--web-primary-white, #FFF);
			text-align: center;
			font-feature-settings: 'clig' off, 'liga' off;

			/* WEB/Paragraph/P1 MD Regular */
			font-family: MTS Sans;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}
}