@import '/src/styles/mixin.scss';

.third {
	height: 400vh;
	margin-top: -50vh;

	@include mobile {
		height: 300vh;	
	}

	.info {
		flex-direction: column;
		transition: gap 0.2s ease;

		.title {
			padding-top: 72px;
			font-family: 'MTS Compact';
			font-style: normal;
			font-weight: 700;
			font-size: 180px;
			line-height: 100%;
			display: flex;
			align-items: center;
			text-align: center;
			text-transform: uppercase;

			color: #FFFFFF;

			transition: margin-top 0.3s ease;
			text-align: center;

			@include mobile {
				font-size: 90px;	
			}
		}

		.images {
			position: relative;
			width: 100%;

			>* {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}
		}

		.subtitle {
			font-family: 'MTS Compact';
			font-style: normal;
			font-weight: 700;

			font-family: 'MTS Compact';
			font-style: normal;
			font-weight: 700;
			font-size: 100px;
			line-height: 142px;
			text-transform: uppercase;
			color: #FFFFFF;

			text-align: center;

			@include mobile {
				font-size: 50px;
				line-height: 100%;
			}
		}
	}
}