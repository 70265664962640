@mixin mobile() {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin tablet() {
    @media (max-width: 1199px) {
        @content;
    }
}
