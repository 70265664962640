@import '/src/styles/mixin.scss';

.wrapper {
    .flexis{
        column-width: 50%;
        column-count: 2;
        column-gap: 32px;

        @include tablet {
            column-width: 100%;
            column-count: 1;
            column-gap: 0;
        }
    }

    .line{
        font-size:14px;
        line-height:20px;
        color:#849AB2;
        padding:20px 0;
        border-bottom:1px solid #677F99;
        display: flex;
        flex-wrap:wrap;
        justify-content:space-between;
    
        page-break-inside: avoid;
        break-inside: avoid;

        .tr{
            text-align:right;
            color:white
        }
    }
}