@import '/src/styles/mixin.scss';

/*--- White Block ---*/
.block-white{
  background:white;
  border-radius: 30px;
  padding:68px 90px 48px;
  display: flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items: center;
}
.left-bw{
  width:420px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-bw{
  width:408px;
  color:#001424;
}

.block-white .swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.block-white .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  transition-property: transform;
  box-sizing: content-box;
}
.block-white .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  display: flex;
}
.block-white .swiper-horizontal {
  touch-action: pan-y;
}
.slider-big-tv {
  width: 100%;

  img {
    object-fit: contain;
    height: 280px;
    width: 100%;

    @include mobile {
      height: 160px;
    }
  }
}
.slider-tv{
  margin:32px -30px 0 !important;
  padding:0 30px !important;
  cursor: pointer;
}
.slider-tv:before,
.slider-tv:after{
  position:absolute;
  content:'';
  width:30px;
  top:0;
  bottom:0;
  background:white;
  z-index:2;
}
.slider-tv:before{
  left:0;
}
.slider-tv:after{
  right:0
}
.slider-tv .swiper-slide{
  height: 100px;
  width:25%;

  @include mobile {
    height: 70px;
  }
}
.slider-tv .swiper-slide img{
  width:100%;
  height:auto;
  border:2px solid white;
  vertical-align:bottom;
  border-radius: 8px;
  object-fit: contain;
  padding: 10px 5px;
}

.slider-tv :global(.swiper-slide.swiper-slide-thumb-active img){
  border-color:#001424;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  z-index: 10;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-prev:global(.swiper-button-disabled),
.swiper-button-next:global(.swiper-button-disabled) {
  display:none;
  cursor: default;
  pointer-events: none;
}
.swiper-button-prev{
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgOCAxNiI+DQogIDxwYXRoIGlkPSLQmtC+0L3RgtGD0YBfMzQiIGRhdGEtbmFtZT0i0JrQvtC90YLRg9GAIDM0IiBkPSJNODEsNDU1LjAxMmExLDEsMCwwLDAtMS43MDYtLjcxNGgwbC0zLjEsMy4xMjlDNzQuMDY2LDQ1OS41ODMsNzMsNDYwLjY2MSw3Myw0NjJzMS4wNjYsMi40MTcsMy4yLDQuNTczbDMuMSwzLjEyOUExLDEsMCwwLDAsODEsNDY4Ljk4OGExLjAxOCwxLjAxOCwwLDAsMC0uMjkzLS43MTVsLTMuMS0zLjEyOWEyNy44NzIsMjcuODcyLDAsMCwxLTIuMjIzLTIuMzljLS4zOS0uNTE3LS4zOS0uNy0uMzktLjc1MVY0NjJjMC0uMDUzLDAtLjIzNC4zOS0uNzUxYTI3Ljg3MiwyNy44NzIsMCwwLDEsMi4yMjMtMi4zOWwzLjEtMy4xMjloMEExLjAxOCwxLjAxOCwwLDAsMCw4MSw0NTUuMDEyWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTczIC00NTQpIi8+DQo8L3N2Zz4NCg==);
  left: 0px;
  right: auto;
}
.swiper-button-next{
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgOCAxNiI+DQogIDxwYXRoIGlkPSLQmtC+0L3RgtGD0YBfMzQiIGRhdGEtbmFtZT0i0JrQvtC90YLRg9GAIDM0IiBkPSJNNzMsNDU1LjAxMmExLDEsMCwwLDEsMS43MDYtLjcxNGgwbDMuMSwzLjEyOWMyLjEzMiwyLjE1NiwzLjIsMy4yMzQsMy4yLDQuNTczcy0xLjA2NiwyLjQxNy0zLjIsNC41NzNsLTMuMSwzLjEyOUExLDEsMCwwLDEsNzMsNDY4Ljk4OGExLjAxOCwxLjAxOCwwLDAsMSwuMjkzLS43MTVsMy4xLTMuMTI5YTI3Ljg3MiwyNy44NzIsMCwwLDAsMi4yMjMtMi4zOWMuMzktLjUxNy4zOS0uNy4zOS0uNzUxVjQ2MmMwLS4wNTMsMC0uMjM0LS4zOS0uNzUxYTI3Ljg3MiwyNy44NzIsMCwwLDAtMi4yMjMtMi4zOWwtMy4xLTMuMTI5aDBBMS4wMTgsMS4wMTgsMCwwLDEsNzMsNDU1LjAxMloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MyAtNDU0KSIvPg0KPC9zdmc+DQo=);
  right: 0px;
  left: auto;
}

.h1{
  font-family: 'MTS WideMedium';
  font-size:32px;
  line-height:36px;
  margin-bottom:16px;
}
.little-p{
  font-size:14px;
  line-height:20px;
}
.specifications{
  display:inline-block;
  width:100%;
  color:#677F99;
  font-size:20px;
  line-height:28px;
  margin-bottom:16px;
}
.block-sp{
  display: flex;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-bottom:8px;
}
.specifications .rt{
  color:#001424;
  text-align:right;
}

.priceBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .gift {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    background: rgba(0, 112, 229, 0.10);
    
    color: #0070E5;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Paragraph/P4 Regular Comp */
    font-family: MTS Compact;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .price{
    display:inline-block;
    font-family: 'MTS Compact';
    font-weight: bold;
    font-size:32px;
    line-height:36px;
    text-align:right;

    @include mobile {
      font-family: MTS Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }
  }
}

@media (max-width: 1199.98px){
  .big-wrapper{
    width:100%
  }
  .block-white{
    padding:58px 0 32px;
    border-radius:0;
  }
  .left-bw,
  .right-bw{
    width:720px;
    margin:auto;
  }
  .slider-big-tv{
    text-align:center;
    width:420px;
  }
  .slider-tv{
    width:460px;
    margin-left:auto;
    margin-right:auto;
  }
  .slider-tv .swiper-slide{
    padding:0;
  }
  .right-bw{
    margin-top:40px
  }
  .specifications{
    font-size:17px;
    line-height:24px;
  }
  .price{
    text-align:left;
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 767.98px) {
  .block-white {
    display:block;
    padding: 28px 0;
  }
  .left-bw,
  .right-bw{
    width: 90%;
  }
  .slider-big-tv{
    width:234px;
  }
  .slider-tv{
    margin:32px -30px 0;
    width:348px;
  }
  .right-bw {
    margin-top: 24px;
  }
  .h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .specifications {
    font-size: 14px;
    line-height: 20px;
  }
  .price {
    font-size: 20px;
    line-height: 24px;
  }
}
/*--- End White Block ---*/

// OTHER
/*--- Select ---*/

.icon_select_mate {
	z-index:100;
	position: absolute;
	height: 24px;
	width:24px;
	top: 50%;
	margin-top:-12px;
	right: 20px;
	transition: all 275ms;

  svg {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }
}
.select_mate {
	z-index:100;
	cursor:pointer;
	margin-top:8px;
	margin-bottom:16px;
	position: relative;
	width: 100%;
	font-size:17px;
	line-height: 44px;
	background:#F2F3F7;
	border-radius: 10px;
}
.select_mate select {
	position: absolute;
	overflow: hidden;
	height: 0px;
	opacity: 0;
}
.selecionado_opcion{
	position:relative;
	z-index:100;
	padding: 0 12px;
	height: 44px;
	cursor: pointer;
}
.cont_list_select_mate {
	position: relative;
	width: 100%;
}
.cont_select_int {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 99;
	overflow: hidden;
	height: 0px;
	width: 100%;
	background-color: #fff;
	padding: 0px;
	margin-bottom: 0px;
	margin-top: 0px;

  li {
    position: relative;
    padding: 0 12px;
    border-top: 1px solid rgba(188, 195, 208, 0.5);
    list-style-type: none;
    margin: 0px;
    opacity:0;
    transition: all 275ms ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(188, 195, 208, 0.25)
    }

    &:last-of-type{
      border-radius: 0;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .info {
      color: var(--text-light-tertiary, #969FA8);
      font-feature-settings: 'clig' off, 'liga' off;

      /* Text/P1 Regular */
      font-family: MTS Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 141.176% */
    }
  }
}

.select_mate[data-selec-open="true"] {
  cursor:default;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;

  .icon_select_mate {
    transform: rotate(180deg);
  }

  .cont_select_int {
    transition: all 275ms;
	  overflow: visible;

    li {
      opacity:1;
    }
  }
}

.cont_select_int:before{
	z-index:-1;
	position:absolute;
	content:'';
	top:-44px;
	left:0;
	right:0;
	bottom:0;
	background:white;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);
}