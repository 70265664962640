.header {
    height: 72px;
    width: 100%; 
    background-color: aliceblue;
    position: fixed;
    z-index: 99;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary-bg);
    min-height: 100vh;
  }
  
  .main > * {
    /* margin-top: 72px; */
  }