@import '/src/styles/mixin.scss';

.first {
	height: 350vh;
	z-index: 9;
	// scroll-behavior: smooth;

	@include mobile {
		height: 250vh;
	}

	&.hideAfter .content::after {
		display: none;
	}

	.content {
		flex-direction: column;
		gap: 132px;

		@include mobile {
			gap: 210px;

			&::after {
				content: '';
				pointer-events: none;
				display: block;
				width: 100%;
				height: 40vh;
				position: absolute;
				z-index: 4;
				bottom: -1%;
				transition: all 0.2s ease;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 80%);
			}
		}

		.info {
			max-width: 850px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 20px;
			margin-top: 8%;
			padding-top: 72px;
			z-index: 4;

			.title {
				font-family: 'MTS UltraExtended';
				font-style: normal;
				font-weight: 700;
				font-size: 48px;
				line-height: 110%;

				display: flex;
				align-items: center;
				text-align: center;
				text-transform: uppercase;

				background-clip: text;
				text-fill-color: transparent;
				color: #E491B4;

				@include mobile() {
					font-size: 25px;
					line-height: 110%;
				}
			}

			.subtitle {
				width: 85%;
				font-family: 'MTS Compact';
				font-style: normal;
				font-weight: 400;
				font-size: 24px;
				line-height: 28px;

				display: flex;
				align-items: center;
				text-align: center;

				color: #FFFFFF;

				@include mobile() {
					font-size: 15px;
					line-height: 21px;
				}
			}
		}

		.backgroundImg {
			width: 100vw;
			position: fixed;
			bottom: 0;
			z-index: 0;
			background-image: url('../../../../public/main/Lines.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position-x: center;
			transition: height 200ms ease;
			z-index: -1;
			pointer-events: none;
		}
	}
}