@import '/src/styles/mixin.scss';

.seven {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	@include mobile {
		margin-top: 50%;
	}

	img {
		width: 95%;

		@include mobile {
			width: 100%;
		}
	}
}